<template>
  <div class="complaints" id="complaints">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Complaints</p>
        <h3>Complaints</h3>
        <h4 id="cmplt1">When and how can I file a complaint?</h4>
        <div class="paragraph">
          <p>
            You may file a complaint when there is anything that you think TayoCash should address or resolve to help improve our products and services, including those with our Partners and Merchants.
          </p>
          <p>
            A <a class="link" href="https://assets.prod.tayocash.com/forms/complaint_form.pdf" download target="_blank">Complaint Form</a> is available and, in some cases, we will be asking for additional information or supporting documents to help address the complaint submitted.
          </p>
          <p>
            For concerns involving specific transactions, please click <a class="link" href="/faq/disputes">here</a> for our FAQs under Transaction Disputes.
          </p>
          <p>
            For assistance and other inquiries, you may call our Service Hotline <span class="vtxt-align">8888-TAYO</span>. We offer quick self-service solutions via our Interactive Voice Response system 24/7. In case you need more help with your concern, you may also speak with our Customer Service Representative from <span class="vtxt-align">Monday to Friday</span> from <span class="vtxt-align">8:00AM to 5:30 PM</span>.
          </p>
        </div>

        <h4 id="cmplt2">Where can I find the complaint form and what happens when I file a complaint?</h4>
        <div class="paragraph">
          <p>
            A downloadable <a class="link" href="https://assets.prod.tayocash.com/forms/complaint_form.pdf" download target="_blank">Complaint Form</a> can be found at the bottom of the <a class="link" href="/helpcenter">Help Center</a> page.
          </p>
          <p>
            When you file a complaint, a ticket is generated for tracking purposes. When creating a ticket, the following details are required:
          </p>
          <ul>
            <li>Complete name</li>
            <li>Mobile number</li>
            <li>Email Address</li>
            <li>Nature and details of the complaint</li>
            <li>Resolution requested</li>
            <li>If the complaint is against TayoCash, a signed
              <a class="link" href="https://assets.prod.tayocash.com/forms/complaint_form.pdf" download target="_blank">Complaint Form</a> should be filled out</li>
            <li>Additional supporting documents, if any</li>
          </ul>
        </div>

        <div class="buttons">
          <a href="/faq/services" class="yellow">BACK</a>
          <a href="/faq/disputes" class="white">NEXT</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'complaints'
}
</script>

<style scoped lang='scss'>
</style>
